import './polyfills';
import { initMobileNav, initDoubleClick } from './header';
import { initHeroCaro, initHomeMap, initReviewsCaro } from './home';
import { contactSubmit, initContactMap } from './contact.js';
import { initFacilityMobile, initFacCaro, initFacMap, initFacilityFilters } from './facility';

initMobileNav();
initDoubleClick();

if(document.querySelector('#home_hero')) {
	initHeroCaro();
}

if(document.querySelector('#home_reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#toggle_fac_info')) {
	initFacilityMobile();
}


if(document.querySelector('#facility_caro')) {
	initFacCaro();
}

if(document.querySelector('#facility_map')) {
	initFacMap();
}

if(document.querySelector('#facility_filters')) {
	initFacilityFilters();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'message']
	});
}

if (document.querySelector('#contact_map')) {
	initContactMap();
}

if (document.querySelector('#home_map')) {
	initHomeMap();
}