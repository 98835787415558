import { AutomatitCarousel } from './automatit_carousel.m';
import {
	getMapsHelper
} from './util/mapsHelper';

export const initHomeMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#home_map'),
				locationElementSelector: '#home_map',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/map_pin.svg" alt="Visit Facility" /></a>`
					};
				}
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(14);
			});
		});
};

export const initHeroCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#home_hero'),
		images: [
			{src: "/static/images/backgrounds/hero1.jpg", alt: ""},
			{src: "/static/images/backgrounds/hero2.jpg", alt: ""},
			{src: "/static/images/backgrounds/hero3.jpg", alt: ""},
		],
		imagesAsBackgrounds: true,
		showDots: true,
		useChevrons: false
	});
};

export const initReviewsCaro = () => {
	window.reviewsCaro = AutomatitCarousel({
		element: document.querySelector('#home_reviews_caro'),
		intervalTiming: false,
		useChevrons: false,
	});
};